import type { ActiveTeam } from "@/types";

export const useActiveTeam = () => {
  const userWatch = useState("userWatchStateForTeams", () => false);
  const { user } = useUser();
  const activeTeamId = useCookie<string>("activeTeam");
  const activeTeam = computed({
    get(): ActiveTeam | null {
      return (
        user.value?.teams.find((team) => team.id === activeTeamId.value) ||
        user.value?.teams.at(0) ||
        null
      );
    },
    set(value: ActiveTeam | null) {
      if (value) {
        activeTeamId.value = value.id;
      }
    },
  });

  if (!userWatch.value) {
    watch(user, () => {
      userWatch.value = true;
      if (user.value && !activeTeam.value) {
        activeTeam.value = user.value?.teams[0];
      }
    });
  }

  return activeTeam;
};
